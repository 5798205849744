import React from 'react';
import classNames from 'classnames';

const sectionsWithBackground = [
  {
    key: 'AdditionalContent',
  },
  {
    key: 'FeaturedContent',
  },
  {
    key: 'Results',
  },
];

const withSection = Component => {
  return ({ module, moduleKey, ...rest }) => {
    const noBackground = !!sectionsWithBackground.find(item => item.key === module);

    // console.info(`module ${module} found`, !noBackground);

    return <Component className={classNames({ 'section-together': !noBackground })} {...rest} />;
  };
};

export default withSection;
