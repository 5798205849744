import camelCase from 'lodash.camelcase';
import kebabCase from 'lodash.kebabcase';
import pascalCase from 'pascalcase';

function parseFlexibleContent(flexibleContent) {
  if (flexibleContent) {
    return flexibleContent.map(item => {
      const { __typename, module, ...rawFields } = item;

      const fields = Object.keys(rawFields).reduce((store, key) => {
        return {
          ...store,
          [camelCase(key)]: rawFields[key],
        };
      }, {});

      const name = module.replace('WordPressAcf_', '');
      const moduleName = pascalCase(name);
      const key = kebabCase(name);

      return {
        key,
        module: moduleName,
        fields,
      };
    });
  }

  return null;
}

export default parseFlexibleContent;
