import React, { createElement, Fragment } from 'react';
import uniqid from 'uniqid';
import merge from 'lodash.merge';
import { SINGLE } from './constants';
import withSection from '../components/organisms/render-section';

function tidyUpEmbed(embedField) {
  return Object.keys(embedField).reduce((store, key) => {
    const item = embedField[key];

    if (item && item.acf) {
      const { acf, ...rest } = item;

      return [
        ...store,
        {
          ...rest,
          ...acf,
        },
      ];
    }

    return [...store, item];
  }, []);
}

function renderFlexibleContent(
  flexibleContent,
  modules,
  injectData = {},
  embeds = [],
  parseEmbedKey,
  renderConditions
) {
  return flexibleContent.map(({ key: moduleKey, module, fields }) => {
    if (modules[module]) {
      if (!renderConditions || !renderConditions[module] || renderConditions[module]()) {
        const additionalData = injectData[module] ? injectData[module] : {};

        const embedFields = embeds.reduce((store, embed) => {
          const { key, type } = embed;

          if (fields[key]) {
            const embedData = tidyUpEmbed(fields[key]);
            let itemKey = key;

            if (parseEmbedKey) {
              itemKey = parseEmbedKey(key);
            }

            if (type === SINGLE && embedData[0]) {
              return {
                ...store,
                [itemKey]: embedData[0],
              };
            }

            return {
              ...store,
              [itemKey]: embedData,
            };
          }

          return store;
        }, {});

        const section = withSection(modules[module]);

        const data = merge(merge(fields, embedFields), additionalData);

        const props = {
          module,
          moduleKey,
          ...data,
        };

        return <Fragment key={uniqid()}>{createElement(section, props)}</Fragment>;
      }
    }

    return null;
  });
}

export default renderFlexibleContent;
